import { useRouter } from "next/router";
import { Layout } from "~/components/leaps/Layout";
import React from "react";

export default function Home({ session }: any) {
  const router = useRouter();

  React.useEffect(() => {
    router.replace("/leaps");
  }, [router]);

  return (<div></div>)
}
